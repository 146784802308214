import { TabOption } from '@faxi/web-component-library';
import { PremiumFeature, PremiumFeaturesNames } from 'models';

const filterFeaturesObject = (values: Record<string, '0' | '1'>) =>
  Object.entries(values).filter(([_, value]) => Boolean(parseInt(value)));

const mapPremiumFeatures = (data: Array<PremiumFeature>) =>
  Object.entries(data).reduce(
    (prevValue, [_, value]) => ({
      ...prevValue,
      [(value as PremiumFeature)?.name]: '1',
    }),
    {
      carpooling: '0',
      walking: '0',
      cycling: '0',
      people_page: '0',
      journeys_page: '0',
      planner_page: '0',
      messages_page: '0',
      gamification_feature: '0',
      map_client_admin: '0',
      event_feature: '0',
      booking_price: '0',
      booking_polyline: '0',
      use_fullname: '0',
      live_map_preview: '0',
      survey: '0',
      emission_optimisation: '0',
      booking_client_admin: '0',
      parking_barrier: '0',
    } as Record<PremiumFeaturesNames, '0' | '1'>
  );

const PREMIUM_FEATURES_TITLES = {
  carpooling: 'Carpooling',
  walking: 'Walking',
  cycling: 'Cycling',
  people_page: 'People',
  journeys_page: 'Journeys',
  planner_page: 'Booking',
  messages_page: 'Messages',
  gamification_feature: 'Gamification',
  parking_barrier: 'Parking barrier management',
  event_feature: 'Events',
  booking_price: 'Journey price',
  booking_polyline: 'Carpools (map view)',
  use_fullname: 'User full name',
  live_map_preview: 'Driver tracking (Live journey)',
  survey: 'Survey',
  emission_optimisation: 'Survey Analysis',
  booking_client_admin: 'Bookings',
  map_client_admin: 'Map',
} as Record<PremiumFeaturesNames, string>;

const PREMIUM_FEATURES_WARNINGS = {
  survey: 'If you disable Survey feature, all active surveys will be canceled',
} as Record<PremiumFeaturesNames, string>;

const PREMIUM_FEATURE_TABS_OPTIONS: Array<TabOption<string>> = [
  { label: 'Enabled', value: '1' },
  { label: 'Disabled', value: '0' },
];

export {
  mapPremiumFeatures,
  filterFeaturesObject,
  PREMIUM_FEATURES_TITLES,
  PREMIUM_FEATURES_WARNINGS,
  PREMIUM_FEATURE_TABS_OPTIONS,
};
