import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const PremiumFeatureTab = styled.div`
  ${flex('column')};
  ${size('100%', 'auto')};

  .feature {
    ${flex('row', 'space-between', 'center')};

    &:not(&:last-of-type) {
      border-bottom: 1px solid var(--SHADE_1_7);
    }

    width: 100%;
    font-weight: 600;
    padding: ${theme.sizes.SIZE_16} 0;

    &--sub {
      padding-top: ${theme.sizes.SIZE_8};
    }

    &__title {
      ${flex('row', 'center', 'center')};
      ${flexGap(theme.sizes.SIZE_12, 'row')};
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
      color: var(--SHADE_1_1);

      &--sub {
        font-weight: 400;
      }

      &__icon {
        ${size(theme.sizes.SIZE_20)};
        color: var(--PRIMARY_1_1);
      }
    }

    &__tab-field {
      ${flex('row', 'space-between', 'center')};
      min-width: 220px;
    }

    &__warning-msg {
      ${flex('row')};
      background-color: var(--LABEL_TEXT_1_7);
      border-radius: ${theme.sizes.SIZE_8};
      color: var(--LABEL_TEXT_DARK_1_1);
      padding: ${theme.sizes.SIZE_16};

      > :first-child {
        margin-right: ${theme.sizes.SIZE_8};
        margin-top: ${theme.sizes.SIZE_2};
      }
    }
  }
`;
