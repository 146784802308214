import { FieldArrayItem, FormFieldArray } from '@faxi/web-form';
import { JourneyParticipant, JourneyParticipantType } from 'models/Journey';
import {
  ManualVerificationFormData,
  VerificationParticipantForm,
} from '../ManualVerificationModal.component';

export const mergeArray = <T>(array: Array<T>): Array<T> => {
  return array.reduce((currentValue: Array<T>, value: T | Array<T>) => {
    if (Array.isArray(value)) {
      currentValue = currentValue.concat(value);
      return currentValue;
    }

    currentValue.push(value);
    return currentValue;
  }, []);
};

export const getParticipantsFields = (fieldArray: FormFieldArray) => {
  return fieldArray?.fields.map(
    (p) => (p as Record<'verified', FieldArrayItem>)['verified']
  );
};

export const mapVerifications = (
  participants: Record<
    Partial<JourneyParticipantType>,
    JourneyParticipant | JourneyParticipant[]
  >
) =>
  mergeArray(
    Object.entries(participants).map(([key, value]) => {
      if (Array.isArray(value)) {
        return (value as Array<JourneyParticipant>).map(
          (passenger) =>
            ({
              ...passenger,
              type: 'passengers',
              verified: Boolean(passenger.pocs_time),
            } as VerificationParticipantForm)
        );
      }

      return {
        ...value,
        type: key,
        verified: Boolean(value.pocs_time),
      } as VerificationParticipantForm;
    })
  );

export const mapOppositeVerifications = (
  data: ManualVerificationFormData,
  fields: FieldArrayItem[],
  pocs_time: string
) =>
  data.participants.reduce((currentValue, value, index) => {
    currentValue = {
      ...currentValue,
      [value.type]:
        value.type !== 'passengers'
          ? { ...value, ...(fields[index].value && { pocs_time }) }
          : [
              ...((currentValue[value.type] as any) ?? []),
              { ...value, ...(fields[index].value && { pocs_time }) },
            ],
    };

    return currentValue;
  }, {} as Record<Partial<JourneyParticipantType>, JourneyParticipant>);
