import { useCallback, useMemo, useRef, useState } from 'react';
import {
  Breadcrumbs,
  Heading,
  SelectOption,
  SortOption,
  Table,
  getColor,
  useQueryParams,
  StatusElement,
  Switch,
  Button,
  Input,
  useUtilities,
} from '@faxi/web-component-library';

import { uniqueId } from 'lodash';
import { TablePageLayout } from 'components';
import FiltersButton from 'components/_molecules/FiltersButton';
import FiltersModal from 'components/_molecules/FiltersModal';
import Icon, { INameExtended } from 'components/Icon';

import { CommunityStatus, UserToCommunity, ConsentType } from 'models';
import { apiUsers } from 'modules';
import { appUri } from 'config';
import { useTablePagination } from 'hooks';
import {
  STATUS_MAP,
  STATUS_TRANSLATIONS,
  USERS_TO_COMMUNITY_FILTERS,
  USER_ORGANISATION_STATUS_MAP,
  USER_ORGANISATION_STATUS_MAP_TRANSLATIONS,
  USER_TYPE_MAP_TRANSLATIONS,
  searchParamsToArray,
} from '../utils';

import {
  STATUS_MAP as COMMUNITY_STATUS_MAP,
  STATUS_TRANSLATIONS as COMMUNITY_STATUS_TRANSLATIONS,
} from '../../Communities/Communities.page';
import { getConsentsFormat } from 'utils/consents';
import { maxLength } from 'validation/general';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

export const validation = {
  search: [maxLength('Maximum number of characters is 40', 40)],
};

const UsersToCommunity = () => {
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const { showOverlay, hideOverlay, showSnackBar, hideSnackBar } =
    useUtilities();

  const {
    params: {
      userStatus,
      communityStatus,
      userToCommunityStatus,
      roles,
      hide_test,
    },
    setQueryParam,
    removeQueryParam,
    setMultipleQueryParams,
  } = useQueryParams<{
    userStatus: string;
    communityStatus: string;
    userToCommunityStatus: string;
    roles: string;
    hide_test?: 'true';
  }>();

  const filterBtnRef = useRef<HTMLButtonElement>(null);

  const filterValues = useMemo(
    () => ({
      userStatus: searchParamsToArray(userStatus),
      communityStatus: searchParamsToArray(communityStatus),
      userToCommunityStatus: searchParamsToArray(userToCommunityStatus),
      roles: searchParamsToArray(roles),
    }),
    [communityStatus, roles, userStatus, userToCommunityStatus]
  );

  const activeFiltersCount = useMemo(
    () =>
      (filterValues.userStatus?.length || 0) +
      (filterValues.communityStatus?.length || 0) +
      (filterValues.userToCommunityStatus?.length || 0) +
      (filterValues.roles?.length || 0),
    [filterValues]
  );

  const translationKeys = useMemo(
    () =>
      ({
        user_id: 'User ID',
        user_firstname: 'Name',
        user_email: 'Email',
        user_status: 'User status',
        user_type: 'User type',
        organisation_id: 'Community ID',
        organisation_name: 'Community Name',
        organisation_status: 'Community Status',
        user_organisation_status: 'User to community status',
        user_consents: 'User Accepted consents',
      } as Record<Partial<keyof UserToCommunity>, string>),
    []
  );

  const renderConsents = useCallback((consents: Array<ConsentType>) => {
    // BE side retrive as some consents for which we dont have description
    // we dont want to show them, so with this const we check if all consents are invalid without description and show empty state
    // and bellow in map function we check for each consent does have description
    const validConsents = consents.some(({ description }) => description);

    return (
      <div className="kinto-users__table__consents">
        {!consents.length || !validConsents ? (
          <small className="kinto-users__table__consents__title">
            There are no accepted consents.
          </small>
        ) : (
          consents.map(
            ({ name, description }) =>
              description && (
                <StatusElement<INameExtended>
                  key={uniqueId(name)}
                  status="canceled"
                  icon="info-circle"
                  enableTooltip
                  tooltipText={description}
                >
                  {name}
                </StatusElement>
              )
          )
        )}
      </div>
    );
  }, []);

  const mapUsersToCommunity = useCallback(
    (users: UserToCommunity[]) =>
      users.map(
        ({
          user_id,
          user_firstname,
          user_lastname,
          user_email,
          user_status,
          user_type,
          organisation_id,
          organisation_name,
          organisation_status,
          user_organisation_status,
          user_consents,
        }: UserToCommunity) =>
          ({
            id: +`${user_id}${organisation_id}`,
            user_id,
            user_firstname:
              !user_firstname || !user_lastname
                ? '-'
                : `${user_firstname} ${user_lastname}`,
            user_email,
            user_status: (
              <StatusElement status={STATUS_MAP[user_status]}>
                {STATUS_TRANSLATIONS[user_status]}
              </StatusElement>
            ),
            user_type: USER_TYPE_MAP_TRANSLATIONS[user_type],
            organisation_id,
            organisation_name,
            organisation_status: (
              <StatusElement
                status={
                  COMMUNITY_STATUS_MAP[organisation_status as CommunityStatus]
                }
              >
                {
                  COMMUNITY_STATUS_TRANSLATIONS[
                    organisation_status as CommunityStatus
                  ]
                }
              </StatusElement>
            ),
            user_organisation_status: (
              <StatusElement
                small
                status={USER_ORGANISATION_STATUS_MAP[user_organisation_status]}
              >
                {
                  USER_ORGANISATION_STATUS_MAP_TRANSLATIONS[
                    user_organisation_status
                  ]
                }
              </StatusElement>
            ),
            user_consents: renderConsents(getConsentsFormat(user_consents)),
          } as any)
      ),
    [renderConsents]
  );

  const {
    data,
    count,
    totalCount,
    totalPages,
    currentPage,
    activeColumnSort,
    search,
    setCount,
    setCurrentPage,
    setSearch,
    setActiveColumnSort,
  } = useTablePagination<UserToCommunity, 'users'>({
    itemsKey: 'users',
    spinnerParentClass: '.kinto-page',
    resetDeps: [
      hide_test,
      userStatus,
      communityStatus,
      userToCommunityStatus,
      roles,
    ],
    deps: [],
    mappingFunction: (values: Array<UserToCommunity>) =>
      mapUsersToCommunity(values),
    initialParams: { sortBy: 'user_id', sortDirection: 'asc' },
    apiRequest: ({
      per_page,
      currentPage,
      searchString,
      sort_by,
      sort_direction,
    }) => {
      return apiUsers.getUsersToCommunity({
        per_page,
        page: currentPage,
        search: searchString,
        sort_by,
        sort_direction,
        userStatus: userStatus,
        organisationStatus: communityStatus,
        userOrganisationStatus: userToCommunityStatus,
        roles: roles,
        hide_test: +!!hide_test,
      });
    },
  });

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<UserToCommunity>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  const onSubmit = useCallback(
    async ({
      userStatus,
      communityStatus,
      userToCommunityStatus,
      roles,
    }: any) => {
      setFiltersModalOpen(false);
      setCurrentPage(1);
      setMultipleQueryParams({
        userStatus,
        communityStatus,
        userToCommunityStatus,
        roles,
      });
    },
    [setCurrentPage, setMultipleQueryParams]
  );

  const handleHideTestUsers = useCallback(() => {
    !hide_test
      ? setQueryParam('hide_test', 'true')
      : removeQueryParam('hide_test');
    setCurrentPage(1);
  }, [hide_test, removeQueryParam, setCurrentPage, setQueryParam]);

  const generateXLSX = useCallback(async () => {
    showOverlay('body');

    const snackBar = showSnackBar(
      {
        text: 'Waiting to download',
        variant: 'loading',
        loadingText: 'Downloading',
      },
      { constant: true }
    );

    try {
      const { data, headers } = await apiUsers.getUsersToCommunityExportXLSX({
        search,
        sort_by: activeColumnSort.sortBy,
        sort_direction: activeColumnSort.sortDirection,
        userStatus: userStatus,
        organisationStatus: communityStatus,
        userOrganisationStatus: userToCommunityStatus,
        roles: roles,
        hide_test: +!!hide_test,
      });

      if (!data) {
        return;
      }

      saveAs(
        new Blob([data], {
          type:
            headers['content-type'] ||
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `Users-to-Community_${dayjs().format('DD-MMMM-YYYY')}`
      );

      hideSnackBar(snackBar);
      hideOverlay('body');
    } catch (e) {
      console.error(e);
    }
  }, [
    activeColumnSort.sortBy,
    activeColumnSort.sortDirection,
    communityStatus,
    hide_test,
    roles,
    userStatus,
    userToCommunityStatus,
    search,
    hideOverlay,
    hideSnackBar,
    showOverlay,
    showSnackBar,
  ]);

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Breadcrumbs
        crumbs={[
          { text: 'Users', href: appUri.USERS },
          { text: 'Users to Community', href: appUri.USERS_TO_COMMUNITY },
        ]}
        className="kinto-users-feedback__breadcrumbs"
      />

      <div className="kinto-users-feedback__title">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__header"
        >
          Users to Community
        </Heading>
      </div>

      <div className="kinto-page__actions">
        <div className="kinto-page__actions__right-side">
          <div className="kinto-page__actions__right-side__users">
            <Input
              placeholder="Search here..."
              value={search}
              prefixIcon={<Icon name="search" />}
              validators={validation.search}
              {...(search && {
                suffixIcon: (
                  <Button
                    variant="ghost"
                    aria-label="Delete input search value"
                    onClick={() => setSearch('')}
                    icon={<Icon name="xmark" />}
                  />
                ),
              })}
              onChange={(value, error) => {
                setSearch(value, error);
                setCurrentPage(1);
              }}
            />

            <Button
              onClick={generateXLSX}
              disabled={data.length === 0}
              variant="outline"
            >
              Export to XLSX
            </Button>

            <Switch
              className="kinto-page__actions__right-side__toggle"
              label="Hide test users"
              value={hide_test === 'true'}
              onChange={handleHideTestUsers}
            />
          </div>

          <FiltersButton
            activeFiltersCount={activeFiltersCount}
            onClick={() => setFiltersModalOpen(true)}
            buttonRef={filterBtnRef}
            className="kinto-page__actions__filter-btn"
          />
        </div>
      </div>

      {data.length === 0 ? (
        <div className="kinto-page__empty-state">
          Sorry, there are no results that match your search.
        </div>
      ) : (
        <Table<UserToCommunity>
          tableId="users-to-community-table"
          breakAtMaxWidth={1200}
          tableData={data}
          translationKeys={translationKeys}
          initialSort={activeColumnSort}
          excludeColumns={['id']}
          excludeSortColumns={['user_consents']}
          paginationData={{
            currentPage,
            limit: count,
            totalCount,
            totalPages,
          }}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          onPageChange={setCurrentPage}
          onLimitChange={(data: SelectOption) => {
            setCurrentPage(1);
            setCount(+data.value);
          }}
          onColumnSortClicked={handleOnColumnSort}
        />
      )}

      {filtersModalOpen && (
        <FiltersModal
          onClose={() => setFiltersModalOpen(false)}
          filters={USERS_TO_COMMUNITY_FILTERS}
          onSubmit={onSubmit}
          triggerRef={filterBtnRef.current!}
          initialData={filterValues}
        />
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default UsersToCommunity;
